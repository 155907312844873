import { useEffect } from 'react'
import { navigate } from 'gatsby'

const RedirectIn = (props) => {
	useEffect ( () => {	
		setTimeout( () => {
			navigate(props.to)
		}, props.in)
	}, [props.in, props.to])
	return null
}

export default RedirectIn