import React, { useEffect, useContext } from "react"
import AccountLayout from '../../components/accountLayout'
import RedirectIn from '../../components/redirectIn'
import AuthGate from "../../components/authGate"
import UiStoreContext from "../../contexts/uiStoreContext"

const ResetPlanFlow = () => {
	const uiStore = useContext(UiStoreContext)
	useEffect(() => {
		uiStore.resetPlanFlow()
	}, [])
	return null
}

const Free = (props) => {
	return <AuthGate {...props} entry="signup">
		<ResetPlanFlow/>
		<AccountLayout message="You're all set!" instructions="Redirecting you to &quot;My Files&quot;...">
			<RedirectIn to="/me" in={2000}/>
		</AccountLayout>
	</AuthGate>
}

export default Free